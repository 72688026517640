$enable-negative-margins: true;
$nav-link-padding-x: false;

// Body
$body-bg: #f8fafc;


// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #DC3555;
$dark-red: #B51F1F;
$tertiary: #2B3035;
$beige: #D1C7B6;
$green: #38c172;
$teal: #009BA1;
$cyan: #6cb2eb;
$teal-rueckenfit: #20C997;

// Variables
$primary: #FFBA00 !default;
$success: #4CCA97 !default;
$info: #85C2F2 !default;
$dark: #212529 !default;

$font-family-sans-serif: "Montserrat", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

//Buttons

$card-hero-col: calc(100% / 7 - ($grid-gutter-width * 3));

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 6,
);

// cards

$card-border-width: false;
