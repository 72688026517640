// Bootstrap
@import 'variables';
@import '~bootstrap/scss/bootstrap';

header {
  img {
    @include media-breakpoint-up(lg) {
      max-width: 98% !important;
    }
    @include media-breakpoint-up(xxl) {
      width: 92%;
    }
  }
}

.hero {
  .container:has(.card-hero) {
    @include media-breakpoint-up(md) {
      position: absolute;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
    }

    .row {
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        display: block;
        background: $dark;
        height: 100%;
        width: calc(100% - var(--bs-gutter-x));
        border-radius: 1rem;
        margin: 0 calc(var(--bs-gutter-x) * .5);
        z-index: -1;

        @include media-breakpoint-up(md) {
          width: 58.33333333%;
        }
      }
    }
  }

  .img-hero {
    aspect-ratio: 1/1;
    width: 100vw;

    @include media-breakpoint-down(sm) {
      margin-left: calc(var(--bs-gutter-x) * -.5);
      border-radius: 0 !important;
    }

    @include media-breakpoint-between(sm, md) {
      position: relative;
      left: 50%;
      aspect-ratio: 16/10;
      z-index: -1;
      transform: translateX(-50%);
      border-radius: 0 !important;
    }
    @include media-breakpoint-up(md) {
      margin-left: 0;
      width: 100%;
      aspect-ratio: 1/1;
    }
    object-fit: cover;
    object-position: right;
  }
}

// cards-section

.card-list {

  .col {

    &:nth-child(1) .card {
      --color: #{$teal-rueckenfit};
    }

    &:nth-child(2) .card {
      --color: #{$red};
    }

    &:nth-child(3) .card {
      --color: #{$tertiary};
    }

    &:nth-child(4) .card {
      --color: #{$beige};
    }

    &:nth-child(5) .card {
      --color: #{$dark-red};
    }

    &:nth-child(6) .card {
      --color: #{$red};
    }

    &:nth-child(7) .card {
      --color: #{$teal};
    }

    .card {
      position: relative;
      z-index: 1;
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top:0;
        left: 0;
        z-index: -2;
        height: 200%;
        width: 100%;
        background: linear-gradient(225deg, var(--color) 0%, #{$white} 35%, #{$white} 65%, var(--color) 100%);
        transition: top .6s ease;
      }

      &:after {
        position: absolute;
        top: 2px;
        left: 2px;
        content: '';
        display: block;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background: $white;
        border-radius: calc(#{$border-radius-xl} - 2px);
        z-index: -1;
      }

      .btn {
        z-index: 1;
        transition: ease-in-out .4s;
      }

      .card-img-top {

        aspect-ratio: 16/10;

        object-fit: cover;
        object-position: right;
      }

      &:hover {

        &:before{
          top: -100%
        }

        .btn {
          background: var(--color);
          border-color: var(--color);
        }

        .btn-lighten {
          color: #{$white};
        }
      }
    }
  }
}

// fonts

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('/fonts/montserrat-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/montserrat-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('/fonts/montserrat-v15-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/montserrat-v15-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/montserrat-v29-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('/fonts/montserrat-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/montserrat-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('/fonts/montserrat-v15-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/montserrat-v15-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
